.selector {
    display: flex;
    align-items: center;
    color: white;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 24px;
    cursor: pointer;
}

.selector img {
    margin-left: 10px;
}