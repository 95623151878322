.selectDate {
    width: 100%;
}

.selectDate.active img {
    transform: rotate(180deg);
}

.selectDateMainBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 16px;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 8px;
    cursor: pointer;
}

.selectDateCalendarBlock {
    display: none;
}

.selectDate.active .selectDateMainBlock {
    border: 1px solid #01963A
}

.selectDate.active .selectDateCalendarBlock {
    display: inline-block;
}