.regionBlock {
    display: flex;
}

.regionBlock_list {
    width: 500px;
    margin-right: 20px;
}

.regionBlock_map {
    width: 100%;
}

.regionBlock_map .locationBlock_map {
    margin-top: 0;
}

.regionBlock_list__noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.regionBlock_list__noData img {
    margin-top: 8px;
}