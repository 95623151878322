.filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
    width: 134px;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    background: #FFFFFF;
    cursor: pointer;
    position: relative;
}

.filter_marginRight {
    margin-right: 16px;
}

.filter img {
    margin-right: 10px;
}

.filter_angle {
    margin-right: 0 !important;
    margin-left: auto;
}

.filterParams {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.activeDot {
    position: absolute;
    right: -3px;
    top: -3px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #01963A;
}