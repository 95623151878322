.userProfile {
    padding: 24px;
    width: 100%;
}

.userProfile_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
}

.userProfile_header__info {
    display: flex;
    align-items: center;
}

.userProfile_header__info__avatar {
    width: 56px;
    height: 56px;
    background: #3F51B5;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    color: white;
}

.userProfile_header__actions {
    display: flex;
    align-items: center;
}

.userProfile_header__actions__edit {
    display: flex;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
}

.userProfile_header__actions__edit img {
    margin-right: 10px;
}

.buttonWithIcon {
    display: flex;
    align-items: center;
}

.buttonWithIcon img {
    margin-right: 10px;
}