@media (max-width: 575.98px) {
    .fullPageMenu {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .fullPageMenu {
        display: none;
    }
}