.collapseItemBlock {
    padding: 16px 10px;
    border-top: #E0E0E0 1px solid;
    width: 100%;
    cursor: pointer;
    position: relative;
}
.collapseItemBlock.active img {
    transform: rotate(180deg);
}

.collapseItemBlock_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapseItemBlock_text {
    margin-top: 16px;
    display: none;
}

.collapseItemBlock.active .collapseItemBlock_text {
    display: block;
}