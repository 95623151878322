.serchLegalPersonBlock {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    margin-bottom: 24px;
    height: 270px;
    overflow-y: scroll;
}

.serchLegalPersonBlock::-webkit-scrollbar {
    width: 5px;
}

.serchLegalPersonBlock::-webkit-scrollbar-track {
    background: white;
}

.serchLegalPersonBlock::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
}