@media (max-width: 575.98px) {
    .advantagesMiniBlock_content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .advantagesMiniBlock_content__item {
        margin-bottom: 16px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .advantagesMiniBlock_content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .advantagesMiniBlock_content__item {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) and (max-width: 1300px) {}