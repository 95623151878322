.createCarBlock_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px
}

.locationBlock {
    margin-top: 50px;
}

.locationBlock_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.locationBlock_map {
    width: 100%;
    height: 600px;
    margin-top: 25px;
}