.fullPageMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    box-shadow: inset -1px 0px 0px #E0E0E0;
    height: 100%;
    position: absolute;
    z-index: 900;
}

.rules-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    padding: 11px 16px;
    width: 100%;
}