.SpecifiedDataLegalPersonBlock {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 24px;
}

.SpecifiedDataLegalPersonBlock_column {
    width: 320px;
}

.SpecifiedDataLegalPersonBlock_column.first_column {
    margin-right: 12px;
}


.SpecifiedDataLegalPersonBlock_column.second_column {
    margin-left: 12px;
}

.SpecifiedDataLegalPersonBlock_column__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 16px !important;
}