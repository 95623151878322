.advantagesBlock {
    margin-top: 60px;
}

.advantagesBlock_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.advantagesBlock_item__img,
.advantagesBlock_item__content {
    width: 100%;
}

.advantagesBlock_item__content {
    margin-top: 40px;
}

.advantagesBlock_item__listItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 480px;
}

.advantagesBlock_item__listItem p {
    width: 100%;
}

.advantagesBlock_item__listItem__dot {
    width: 6px !important;
    height: 6px !important;
    background: #01963A;
    border-radius: 100%;
}