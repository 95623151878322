.burgerMenu {
    box-shadow: inset -1px 0px 0px #E0E0E0;
    height: 100%;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    background: white;
    position: fixed;
    z-index: 1000;
    width: 280px;
    right: -400px;
    top: 0;
    padding: 24px;
}

.burgerMenu.active {
    bottom: 0;
    right: 0;
}

.burgerMenu_header {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.burgerMenuBg {
    position: fixed;
    background: rgba(14, 20, 18, 0.4);
    height: 100%;
    z-index: 100;
    width: 100vw;
    opacity: 0.33;
    top: 0;
    left: 0;
}

.burgerMenu_buttons {
    display: flex;
    flex-direction: column;
}

.burgerMenu_links {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

}