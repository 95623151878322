.header {
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #E0E0E0;
    padding: 12px 32px;
    display: flex;
    align-items: center;
    width: 100%;
}

.userHeader {
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #E0E0E0;
    padding: 12px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.userHeader_rightPart__avatar {
    margin-left: 32px;
}

.userMenu {
    cursor: pointer;
}

.userHeader_rightPart__menu {
    margin-left: 10px;
}

.header_link,
.footer_link {
    color: #0E1412;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-left: 32px;
}

.header_link.active-header-link, .active-header-link{
    color: #01963A !important;
}