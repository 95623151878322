@media (max-width: 575.98px) {
    .userProfile_header, .userProfile_header__info, .userProfile_header__actions {
        flex-direction: column;
    }
    .userProfile_header__actions {
        margin-top: 15px;
    }
    .userProfile_header__actions__edit{
        margin-bottom: 15px;
    }
    .userProfile_header__actions button{
        margin: 5px 0 !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .userProfile_header {
        flex-direction: column;
    }
    .userProfile_header__actions {
        margin-top: 15px;
    }
}