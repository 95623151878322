@media (max-width: 575.98px) {
    .mainPage_content {
        padding: 0 30px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .mainPage_content {
        padding: 0 30px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .mainPage_content {
        padding: 0 70px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .mainPage_content {
        padding: 0 70px;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {}