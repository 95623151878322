.greenButton {
    color: #FFFFFF;
    background: #01963A;
    border-radius: 100px;
    padding: 7px 16px;
}

.whiteButton {
    color: #01963A;
    background: #FFFFFF;
    border-radius: 100px;
    padding: 7px 16px;
    border: 1px solid #01963A;
}

.whiteButton2 {
    background: #FFFFFF;
    border-radius: 100px;
    padding: 11px 32px;
    color: #0E1412;
}