@media (max-width: 575.98px) {

    .header,
    .footer {
        flex-direction: column;
        align-items: flex-start;
    }

    .header_link,
    .footer_link {
        margin-left: 0;
        margin-top: 15px;
    }

    .userHeader, .userHeader_leftPart {
        flex-direction: column;
        align-items: flex-start;
    }

    .userHeader_leftPart .header_link {
        display: block;
    }

    .userHeader_rightPart .header_link {
        margin-right: 10px;
    }

    .userHeader_rightPart__avatar {
        margin-left: 0;
    }

    .userHeader_rightPart {
        margin-left: auto;
        position: absolute;
        top: 12px;
        right: 32px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .footer {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer_link {
        margin-left: 0;
        margin-top: 15px;
    }

    .userHeader, .userHeader_leftPart {
        flex-direction: column;
        align-items: flex-start;
    }

    .userHeader_leftPart .header_link {
        display: block;
    }

    .userHeader_rightPart .header_link {
        margin-right: 10px;
    }

    .userHeader_rightPart__avatar {
        margin-left: 0;
    }

    .userHeader_rightPart {
        margin-left: auto;
        position: absolute;
        top: 12px;
        right: 32px;
    }

    .header_link {
        margin-left: 0;
        margin-top: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}