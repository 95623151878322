@media (max-width: 575.98px) {
    .findOrderBlock_item__img {
        display: none;
    }

    .findOrderBlock_item__steps__item {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .findOrderBlock_item__steps__item_content {
        width: 150px;
        margin-bottom: 25px;
        text-align: start;
    }

    .findOrderBlock_item__steps__yellowLine {
        width: 100%;
    }

    .findOrderBlock_item {
        text-align: center;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .findOrderBlock_item__img {
        display: none;
    }

    .findOrderBlock_item__steps__item_content {
        width: 150px;
    }

    .findOrderBlock_item__steps__yellowLine {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .findOrderBlock_item__img {
        width: 200px;
        height: 200px;
    }

    .findOrderBlock_item__content {
        width: 450px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .findOrderBlock_item__img {
        width: 250px;
        height: 250px;
    }

    .findOrderBlock_item__content {
        width: 600px;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .findOrderBlock_item__img {
        width: 250px;
        height: 250px;
    }
}