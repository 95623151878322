@import "~react-image-gallery/styles/css/image-gallery.css";
@import url('colors.css');
@import url('paragrafStyles.css');
@import url('media.css');
@import url('buttons.css');

p {
    margin: 0 !important;
}

button {
    border: none;
    cursor: pointer;
}

a {
    color: #000;
    text-decoration: none;
}

input:hover,
input:focus,
.ant-upload:hover,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused {
    border-color: #01963A !important;
    box-shadow: none !important;
}

.ant-form-item-explain-error {
    color: #D32F2F !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.4px !important;
    font-style: italic !important;
}

.ant-upload {
    background-color: white !important;
    padding: 16px 32px !important;
}

.ant-upload.ant-upload-drag {
    border: 1px dashed #01963A !important;
}

.editUpload .ant-upload {
    background-color: transparent !important;
    padding: 0 !important;
}

.active-link {
    background: #F4F4F4;
    border-left: 2px solid #01963A;
    box-shadow: inset -1px 0px 0px #E0E0E0;
}

.active-link path,
.header-active-link path {
    stroke: #01963A;
}

.upload-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.statusOnTable {
    padding: 4px 8px;
    color: #FFFFFF;
    border-radius: 24px;
    width: fit-content;
}

.tableIconsBlock {
    display: flex;
    justify-content: flex-end;
}

.tableIconsBlock img {
    cursor: pointer;
    margin-left: 16px;
}

.profileUpload {
    width: 56px;
    height: 56px;
    background: rgba(81, 81, 81, 0.75);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-dropdown {
    border: 1px solid #01963A !important;
    border-radius: 8px !important;
}

.ant-select-focused .ant-select-selector,
.ant-select:hover .ant-select-selector,
textarea:hover,
textarea:focus {
    border: 1px solid #01963A !important;
    box-shadow: none !important;
}

.ant-checkbox-inner {
    border-color: #C8C8C8 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #01963A !important;
    border-color: #01963A !important;
}

.ant-checkbox-wrapper {
    margin-inline-start: 0 !important;
}

.ant-breadcrumb-link,
.ant-breadcrumb-separator {
    transition: none !important;
    color: #0E1412 !important;
}

.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none !important;
}

.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    display: inline-block !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    line-height: 1 !important;
    content: "*" !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #A8A8A8 !important;
    background-color: #01963A !important;
}

.ant-radio:hover .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #01963A !important;
}

.twoColumnForm {
    display: flex;
    justify-content: space-between;
}

.twoColumnForm_column {
    width: 49%;
}

.twoColumnForm:nth-child(1) {
    margin-right: 10px;
}

.buttonWithImage {
    display: flex;
    align-items: center;
}

.buttonWithImage img {
    margin-right: 10px;
}

.uploadButton {
    background: rgb(1, 150, 58);
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 16px;
    width: fit-content;
}

.rdrDateDisplayWrapper {
    background-color: transparent !important;
}

.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    color: rgb(1, 150, 58) !important;
}

.rdrDateDisplayItemActive {
    border-color: rgb(1, 150, 58) !important;
}

input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.image-gallery-thumbnail:hover,
.image-gallery-thumbnail.active {
    border: 4px solid rgb(1, 150, 58) !important;
}

.image-gallery-icon:hover {
    color: rgb(1, 150, 58) !important;
}

.image-gallery-icon:focus {
    outline: none !important;
}

.image-gallery-bullets .image-gallery-bullet.active {
    transform: scale(1.2);
    border: 1px solid rgb(1, 150, 58) !important;
    background: rgb(1, 150, 58) !important;
}

.image-gallery-bullet:hover {
    border: 1px solid rgb(1, 150, 58) !important;
    background: rgb(1, 150, 58) !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 500px !important;
}

.scrollContent {
    overflow-x: scroll;
}

.scrollContent::-webkit-scrollbar {
    height: 0;
}