.steps {
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    height: fit-content;
    justify-content: space-between;
}

.steps_line {
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    height: 8px;
    position: absolute;
    width: calc(100% - 150px);
    top: 22px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
}

.steps_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.steps_item__title {
    width: 150px;
    text-align: center;
    z-index: 11;
}

.steps_item__lastCircule {
    width: 36px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
}

.steps_item__lastCircule.activeStep {
    background: #01963A;
}

.steps_item__secondCircule {
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
}

.steps_item__mainCircule {
    border: 1px solid #C8C8C8;
    border-radius: 100%;
    display: flex;
}