@media (max-width: 575.98px) {

    .chatsBlock_main__correspondence,
    .chatsBlock_main__items.activeChat {
        display: none;
    }

    .chatsBlock_main__correspondence.activeChat,
    .chatsBlock_main__items {
        display: block;
        margin-left: 0;
    }

    .chatsBlock_header {
        flex-direction: column;
    }

    .chatsBlock_main__items {
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {

    .chatsBlock_main__correspondence,
    .chatsBlock_main__items.activeChat {
        display: none;
    }

    .chatsBlock_main__correspondence.activeChat,
    .chatsBlock_main__items {
        display: block;
        margin-left: 0;
    }

    .chatsBlock_main__items {
        width: 100%;
    }

    .chatsBlock_header {
        flex-direction: column;
    }

    .chatsBlock_header__switch {
        margin-left: 0;
        margin-top: 15px;
        width: 100%;
    }
}