.userLayout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.userLayout_contentBlock {
    flex-grow: 3;
    display: flex;
    position: relative;
}

.outletLayout {
    width: calc(100% - 48px);
    margin-left: 48px;
    position: relative;
}

.userMenu, .userHeader_rightPart {
    display: flex;
    align-items: center;
}

.userHeader_rightPart .header_link {
    margin-top: 0;
}

.userHeader_rightPart__avatar {
    width: 32px;
    height: 32px;
    border-radius: 1000px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-left: 16px;
}

.userHeader_rightPart__avatar__text {
    width: 32px;
    height: 32px;
    background: #3F51B5;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-left: 16px;
}