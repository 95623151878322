.seasonCollapseBlock {
    overflow-x: scroll;
}

.seasonCollapseBlock::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}

.seasonCollapseBlock::-webkit-scrollbar-track {
    background: white;
}

.seasonCollapseBlock::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
}