.messageGroupCard {
    display: flex;
    width: fit-content;
    flex-direction: row;
    justify-content: flex-end;
    white-space: nowrap;
    align-items: center;
    padding: 3px 16px;
    background: #FFFFFF;
    border: 1px solid #43A047;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;
    height: 32px;
    position: relative;
}

.messageGroupCard.active {
    background: #F4F4F4;
}

.messageGroupCard_notification {
    background: #01963A;
    border-radius: 24px;
    min-width: 26px;
    height: 25px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messageGroupCard_closeIcon {
    width: 12px;
    height: 12px;
    border: 1px solid #01963A;
    background-color: #FFFFFF;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messageGroupCard_closeIcon img {
    width: 10px;
    height: 10px;
}