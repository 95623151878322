.companyCard {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor: pointer;
    width: 100%;
    padding: 10px;
}

.companyCard.active{
    border-bottom: 2px solid #01963A;
}

.companyCard span {
    font-weight: 600;
}