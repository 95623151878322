.regionCard {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #E4E4E4;
    background: #F4F4F4;
    border-radius: 8px;
    cursor: pointer;
}

.regionCard.active {
    background: #FFFFFF;
    border: 2px solid #01963A;
}