.addMessageBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(39, 51, 51, 0.24);
    border-radius: 16px;
    height: fit-content;
}

.addMessageBlock_plusIcon,
.addMessageBlock_messageIcon {
    cursor: pointer;
}

.addMessageBlock_input {
    width: 100%;
    margin: 0 15px;
    border: none;
    outline: none;
    box-shadow: none;
    resize: none;
}

.addMessageBlock_input:hover,
.addMessageBlock_input:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.addMessageBlock_input::-ms-clear {
    display: none;
}

.addMessageUpload .ant-upload.ant-upload-select {
    display: none !important;
}

.addMessageForm .ant-form-item {
    margin-bottom: 0 !important;
}

.addMessageForm .ant-form-item-control-input {
    min-height: 0 !important;
}

.addMessageBlock_submit {
    background-color: transparent;
}