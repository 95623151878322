@media (max-width: 575.98px) {

    .allUsersBlock {
        padding: 15px;
    }

    .allUsersBlock_header,
    .allUsersBlock_header__leftHalf,
    .allUsersBlock_header__rightHalf {
        flex-direction: column;
        align-items: center;

    }

    .allUsersBlock_header__leftHalf .search {
        margin-left: 0;
        margin-top: 16px;
    }

    .allUsersBlock_header__rightHalf .filter {
        margin-right: 0;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {

    .allUsersBlock_header,
    .allUsersBlock_header__leftHalf,
    .allUsersBlock_header__rightHalf {
        flex-direction: column;
        align-items: center;

    }

    .allUsersBlock_header__leftHalf .search {
        margin-left: 0;
        margin-top: 16px;
    }

    .allUsersBlock_header__rightHalf .filter {
        margin-right: 0;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    .allUsersBlock_header__leftHalf,
    .allUsersBlock_header__rightHalf {
        flex-direction: column-reverse;
    }

    .allUsersBlock_header__leftHalf {
        align-items: flex-start;
    }

    .allUsersBlock_header__leftHalf .search {
        margin-left: 0;
        margin-bottom: 16px;
    }

    .allUsersBlock_header__rightHalf {
        align-items: flex-end;
    }

    .allUsersBlock_header__rightHalf .filter {
        margin-right: 0;
        margin-top: 16px;
    }
}