.secondTypeFilterBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 8px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    flex-wrap: nowrap;
    cursor: pointer;
    position: relative;
    margin: 4px 7px 4px 0;
}

.secondTypeFilterBlockColor {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 8px;
    border-radius: 24px;
    flex-wrap: nowrap;
    cursor: pointer;
    position: relative;
    margin: 4px 7px 4px 0;
}

.secondTypeFilterBlock p {
    width: max-content;
}

.secondTypeFilterBlock img {
    margin-left: 16px;
}

.secondTypeFilterParams {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 200px;
    overflow-y: scroll;
}

.secondTypeFilterParams_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.secondTypeFilterParams::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.secondTypeFilterParams::-webkit-scrollbar-track {
    background: rgb(185, 185, 185);
}

.secondTypeFilterParams::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
}