@media (max-width: 575.98px) {

    .createCarBlock_header {
        flex-direction: column;
        align-items: center;

    }

    .createCarBlock_header .textHeading {
        margin-bottom: 16px !important;
    }

    .locationBlock_header {
        flex-direction: column;
        justify-content: center;
    }

    .locationBlock_header button {
        margin-top: 16px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .locationBlock_header {
        flex-direction: column;
        justify-content: center;
    }

    .locationBlock_header button {
        margin-top: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .createCarBlock {
        display: flex;
    }
    .createCarBlock_forms {
        width: 100px;
        flex: 1;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}