.problemsBlock_item {
    display: flex;
}

.problemsBlock_item__column {
    width: 100%;
}

.problemItem_listItem {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.problemItem_listItem__img {
    border: 2px solid #01963A;
    box-shadow: 0px 2px 8px rgba(7, 61, 27, 0.15);
    border-radius: 8px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 20px;
    margin-right: 20px;
}
