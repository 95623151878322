.notificationCard {
    display: flex;
    flex-direction: column;
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    background: #FFFFFF;
    margin-top: 24px;
    /* cursor: pointer; */
}

.notificationCard_textBlock {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notificationCard_textBlock img {
    cursor: pointer;
}

.notificationCard_dateBlock {
    background: rgba(120, 190, 32, 0.08);
    padding: 8px 16px;
}