.allUsersBlock {
    padding: 24px;
}

.deleteNotificationButtons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.allUsersBlock_header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.allUsersBlock_header__leftHalf, .allUsersBlock_header__rightHalf {
    display: flex;
    align-items: center;
}