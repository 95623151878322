.technicCard {
    padding: 10px;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    display: flex;
    width: 412px;
    cursor: pointer;
    margin-bottom: 8px;
    background-color: #FFFFFF;
}

.technicCard img {
    width: 96px;
    height: 96px;
    border-radius: 8px;
    margin-right: 16px;
}

.technicCard_description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.technicCard_description__status {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 24px;
    padding: 4px 8px;
}

@media (max-width: 575.98px) {
    .technicCard {
        width: fit-content;
    }
}