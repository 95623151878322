@media (max-width: 575.98px) {
    .signUpBlock {
        display: block;
        text-align: center;
        padding: 29px 20px;
    }

    .signUpBlock .whiteButton2 {
        margin-top: 16px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .signUpBlock {
        display: block;
        text-align: center;
        padding: 29px 20px;
    }

    .signUpBlock .whiteButton2 {
        margin-top: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) and (max-width: 1300px) {}