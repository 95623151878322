.advantagesMiniBlock_content {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.advantagesMiniBlock_content__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 380px;
}

.advantagesMiniBlock_content__item__img {
    width: 120px;
    height: 120px;
    background: #E6FFE8;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}