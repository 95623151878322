@media (max-width: 575.98px) {
    .problemsBlock_item, .problemItem_listItem {
        flex-direction: column;
        text-align: center;
    }

    .problemItem_listItem__img {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .problemsBlock_item__column {
        margin-bottom: 20px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .problemsBlock_item, .problemItem_listItem {
        flex-direction: column;
        text-align: center;
    }

    .problemItem_listItem__img {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .problemsBlock_item__column {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .problemsBlock_item, .problemItem_listItem {
        flex-direction: column;
        text-align: center;
    }

    .problemItem_listItem__img {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .problemsBlock_item__column {
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) and (max-width: 1300px) {}