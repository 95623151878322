@media (max-width: 575.98px) {
    .steps {
        flex-direction: column;
    }
    .steps_item {
        margin-bottom: 24px;
        flex-direction: row;
    }
    .steps_line {
        width: 8px;
        height: 85%;
        left: 20px;
        right: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .steps {
        flex-direction: column;
    }
    .steps_item {
        margin-bottom: 24px;
        flex-direction: row;
    }
    .steps_line {
        width: 8px;
        height: calc(100% - 50px);
        left: 20px;
        right: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .steps {
        flex-direction: column;
    }
    .steps_item {
        margin-bottom: 24px;
        flex-direction: row;
    }
    .steps_line {
        width: 8px;
        height: calc(100% - 50px);
        left: 20px;
        right: 100%;
    }
}