@media (max-width: 575.98px) {
   .switch {
      flex-direction: column;
      width: 100%;
   }

   .switch_item:nth-child(1) {
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;
   }

   .switch_item:nth-last-child(1) {
      border-bottom-left-radius: 8px;
   }
}

@media (min-width: 576px) and (max-width: 767.98px) {
   .switch {
      flex-direction: column;
      width: 100%;
   }

   .switch_item:nth-child(1) {
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;
   }

   .switch_item:nth-last-child(1) {
      border-bottom-left-radius: 8px;
   }
}