@media (max-width: 575.98px) {
    .descriptionBlock {
        padding: 16px 20px;
        background-image: url('assets/images/landing/mainBG-mobile.png');
    }

    .descriptionBlock_title {
        font-size: 22px !important;
    }

    .descriptionBlock_subText {
        font-size: 14px;
    }

    .descriptionBlock_text {
        margin-top: 70px;
    }

    .descriptionBlock_menu .header_link,
    .descriptionBlock_menu .twoButton {
        display: none;
    }

    .descriptionBlock_menu_links__burger {
        display: block;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .descriptionBlock {
        padding: 16px 30px;
        background-image: url('assets/images/landing/mainBG-mobile.png');
    }

    .descriptionBlock_title {
        font-size: 24px;
    }

    .descriptionBlock_subText {
        font-size: 14px;
    }

    .descriptionBlock_text {
        margin-top: 70px;
    }

    .descriptionBlock_menu .header_link,
    .descriptionBlock_menu .twoButton {
        display: none;
    }

    .descriptionBlock_menu_links__burger {
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .descriptionBlock {
        padding: 16px 70px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .descriptionBlock {
        padding: 16px 70px;
    }
}

@media (min-width: 1200px) {}