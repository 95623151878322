.rulesPage {
    width: 100%;
    min-height: 100%;
    background-color: white;
    flex: 1;
    padding: 10px 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
}

.rulesPage_content {
    padding: 24px 20px;
    flex: 1;
    margin-left: 250px;
}