@media (max-width: 575.98px) {
    .SpecifiedDataLegalPersonBlock {
        flex-direction: column;
    }
    .SpecifiedDataLegalPersonBlock_column.first_column,
    .SpecifiedDataLegalPersonBlock_column.second_column {
        margin: 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .SpecifiedDataLegalPersonBlock {
        flex-direction: column;
    }
    .SpecifiedDataLegalPersonBlock_column.first_column,
    .SpecifiedDataLegalPersonBlock_column.second_column {
        margin: 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}