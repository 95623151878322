@media (max-width: 575.98px) {
    .regionBlock {
        flex-direction: column;
    }

    .regionBlock_list {
        width: 100%;
        margin-bottom: 24px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .regionBlock {
        flex-direction: column;
    }

    .regionBlock_list {
        width: 100%;
        margin-bottom: 24px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .regionBlock {
        flex-direction: column;
    }

    .regionBlock_list {
        width: 100%;
        margin-bottom: 24px;
    }
}