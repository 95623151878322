.MarketFilterBlock {
    padding: 4px 24px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E4E4;
    flex-wrap: wrap;
}

.MarketAllTechics__block {
    /* display: flex; */
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    position: absolute;
}

.MarketAllTechics__block__map {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    position: absolute;
}

.MarketAllTechics__technics {
    padding: 24px;
    min-width: fit-content;
    overflow-y: scroll;
}

.MarketAllTechics__technics__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 19px;
}

.MarketAllTechics__technics__header img {
    cursor: pointer;
}

.MarketAllTechics__technics::-webkit-scrollbar {
    width: 6px;
    height: 0;
}

.MarketAllTechics__technics::-webkit-scrollbar-track {
    background: rgb(185, 185, 185);
}

.MarketAllTechics__technics::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
}

.marketDetalPage {
    padding: 27px;
    display: flex;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    flex: 1;
}

.marketDetalPageWithoutFlex {
    padding: 27px;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
}

.marketDetalPage__left {
    margin-right: 30px;
    width: calc(100% - 444px);
}

.marketDetalPage__right {
    width: 412px;
}

.flexBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
}

.flexBlock.flex_start {
    align-items: flex-start;
}

.statusBlock {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
    background: #43A047;
    border-radius: 24px;
    margin-left: 16px;
}

.otherInfo2 {
    display: none;
}

.createReply {
    width: 100%;
    background-color: #FFFFFF;
    padding: 26px 24px;
    height: 100%;
}

.createReply_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
}

.createReply_header__buttons .whiteButton {
    margin-right: 8px;
}