.chatsBlock {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 56px);
}

.chatsBlock_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 0 1 auto;
}

.chatsBlock_header__switch {
    margin-left: 55px;
}

.chatsBlock_main {
    margin-top: 26px;
    display: flex;
    height: calc(100% - 62px);
    flex: 1 2 auto;
}

.chatsBlock_main__items {
    width: 360px;
}

.chatsBlock_main__correspondence {
    width: 100%;
    margin-left: 10px;
}

.chatsBlock_main__correspondence__cleanBlock {
    background: rgba(1, 150, 58, 0.15);
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
}

.chatsBlock_main__correspondence__cleanBlock__text {
    padding: 4px 8px;
    background: #F4F4F4;
    border-radius: 24px;
    display: inline;
}

.chatsBlock_main__items__masseges {
    margin-top: 8px;
    max-height: calc(100% - 42px);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
}

.chatsBlock_main__items__masseges::-webkit-scrollbar {
    width: 0px;
    height: 0;
}

.chatsBlock_main__items__masseges::-webkit-scrollbar-track {
    background: rgb(185, 185, 185);
}

.chatsBlock_main__items__masseges::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
}

.chatsBlock_main__items__groups {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-top: 8px;
    width: 100%;
    padding-bottom: 5px;
}

.chatsBlock_main__items__groups::-webkit-scrollbar {
    width: 0px;
    height: 5px;
}

.chatsBlock_main__items__groups::-webkit-scrollbar-track {
    background: rgb(185, 185, 185);
}

.chatsBlock_main__items__groups::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
}