.selectDateBlock {
    display: flex;
}

.selectDateBlock_item {
    width: 100%;
}

.firstItem {
    margin-right: 12px;
}

.secondItem {
    margin-left: 12px;
}

@media (max-width: 575.98px) {
    .selectDateBlock {
        display: block;
    }

    .selectDateBlock_item {
        width: 100%;
    }

    .firstItem {
        margin-right: 0;
    }

    .secondItem {
        margin-left: 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .selectDateBlock {
        display: block;
    }

    .selectDateBlock_item {
        width: 100%;
    }

    .firstItem {
        margin-right: 0;
    }

    .secondItem {
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .selectDateBlock {
        display: block;
    }

    .selectDateBlock_item {
        width: 100%;
    }

    .firstItem {
        margin-right: 0;
    }

    .secondItem {
        margin-left: 0;
    }
}