@media (max-width: 575.98px) {
    .infoBlock.twoRow, .twoRow {
        flex-direction: column;
    }

    .infoBlock {
        padding: 10px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .infoBlock.twoRow, .twoRow {
        flex-direction: column;
    }
}