@media (max-width: 575.98px) {

    .advantagesBlock_item {
        flex-direction: column-reverse !important;
    }

    .advantagesBlock_item__content {
        text-align: center;
        justify-content: center;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {

    .advantagesBlock_item {
        flex-direction: column-reverse !important;
    }

    .advantagesBlock_item__content {
        text-align: center;
        justify-content: center;
    }

    .advantagesBlock_item__listItem {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .advantagesBlock_item__img {
        width: 400px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .advantagesBlock_item__img {
        width: 500px;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {}