.search {
    padding: 7px 16px;
    width: 240px;
    background: #F4F4F4;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.search img {
    margin-right: 16px;
}

.search input {
    outline: none;
    border: 0;
    background-color: #F4F4F4;
}

.search input::placeholder {
    color: #A8A8A8;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.search_marginLeft {
    margin-left: 16px;
}