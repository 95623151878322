@media (max-width: 575.98px) {
    .twoColumnForm {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .twoColumnForm_column {
        width: 100% !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .twoColumnForm {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .twoColumnForm_column {
        width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .twoColumnForm {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .twoColumnForm_column {
        width: 100% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}