.descriptionBlock {
    padding: 16px 130px;
    height: 504px;
    background-image: url('assets/images/landing/mainBG.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.descriptionBlock_text {
    margin-top: 110px;
}

.descriptionBlock_subText {
    max-width: 490px;
}

.descriptionBlock_menu,
.descriptionBlock_menu_links {
    display: flex;
    align-items: center;
}

.descriptionBlock_menu {
    justify-content: space-between;
}

.descriptionBlock_menu_links__burger {
    display: none;
    cursor: pointer;
}