.messageCard {
    padding: 12px;
    width: 100%;
    cursor: pointer;
    margin-top: 4px;
}

.messageCard:hover{
    background: #F4F4F4;
}

.messageCard.active {
    background: #F4F4F4;
}

.messageCard_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new_messages_count {
    background: #01963A;
    border-radius: 24px;
    display: flex;
    padding: 1px 6px;
}