.findOrderBlock_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
}

.findOrderBlock_item__steps {
    margin-top: 40px;
}


.findOrderBlock_item__steps__item, .findOrderBlock_item__steps__item_content {
    display: flex;
}

.findOrderBlock_item__steps__item {
    justify-content: space-between;
}

.findOrderBlock_item__steps__item_content {
    width: 200px;
}

.findOrderBlock_item__steps__item_content img {
    margin-right: 6px;
    width: 19px;
    height: 19px;
}

.findOrderBlock_item__img {
    width: 350px;
    height: 350px;
}

.findOrderBlock_item__steps__yellowLine {
    width: -webkit-fill-available;
}