.addRange {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    color: #FFF;
    border: 1px solid #01963A;
    border-radius: 100px;
    background-color: #01963A;
    font-weight: 500;
    width: 100%;
    padding: 10px;
}

.deleteRage {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px;
}

.deleteRage p {
    border: 1px solid #D32F2F;
    border-radius: 100px;
    font-weight: 500;
    padding: 10px;
    margin-right: 16px !important;
}
.rdrDayInPreview {
    color: #01963A !important;
}