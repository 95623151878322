@media (max-width: 575.98px) {
    .rulesPage_content {
        margin-left: 0px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .rulesPage_content {
        margin-left: 0px;
    }
}