.imagesBlock {
    margin-bottom: 8px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.imagesBlock img {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
}