.p2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

.p12_400 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.p14_400 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.p14_500 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.p14_600 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.p16_400 {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.p16_500 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.p18_500 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.p24_600 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

.p36_700 {
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
}

.p_mb4 {
    margin-bottom: 4px !important;
}

.p_mb8 {
    margin-bottom: 8px !important;
}

.p_mb12 {
    margin-bottom: 12px !important;
}

.p_mb16 {
    margin-bottom: 16px !important;
}

.p_mb20 {
    margin-bottom: 20px !important;
}

.p_mb24 {
    margin-bottom: 24px !important;
}

.p_mr8 {
    margin-right: 8px !important;
}

.p_mr16 {
    margin-right: 16px !important;
}

.p_mt24 {
    margin-top: 24px !important;
}

.p_ml8 {
    margin-left: 8px !important;
}