@media (max-width: 575.98px) {

    .MarketAllTechics__block,
    .MarketAllTechics__block__map {
        flex-direction: column;
    }

    .MarketAllTechics__technics {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .marketDetalPage {
        flex-direction: column-reverse;
    }

    .marketDetalPage__left {
        margin-right: 30px;
        width: 100%;
    }

    .marketDetalPage__right {
        width: 100%;
    }

    .otherInfo {
        display: none;
    }

    .otherInfo2 {
        display: block;
    }
    
    .createReply_header {
        flex-direction: column;
    }

    .createReply_header__buttons {
        margin-top: 24px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {

    .MarketAllTechics__block,
    .MarketAllTechics__block__map {
        flex-direction: column;
    }

    .MarketAllTechics__technics {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .marketDetalPage {
        flex-direction: column-reverse;
    }

    .marketDetalPage__left {
        margin-right: 30px;
        width: 100%;
    }

    .marketDetalPage__right {
        width: 100%;
    }

    .otherInfo {
        display: none;
    }

    .otherInfo2 {
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    .MarketAllTechics__block,
    .MarketAllTechics__block__map {
        flex-direction: column;
    }

    .MarketAllTechics__technics {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .marketDetalPage {
        flex-direction: column-reverse;
    }

    .marketDetalPage__left {
        margin-right: 30px;
        width: 100%;
    }

    .marketDetalPage__right {
        width: 100%;
    }

    .otherInfo {
        display: none;
    }

    .otherInfo2 {
        display: block;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}