.seasonCollapse {
    min-width: 1000px;
}

.seasonCollapse_header {
    padding: 7px 14px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
}

.seasonCollapse_header__left {
    display: flex;
    align-items: center;
}

.seasonCollapse_content__buttons {
    padding: 7px 16px;
    border: 1px solid #E4E4E4;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
    margin-top: 4px;
    margin-bottom: 8px;
}

.seasonCollapseItem {
    margin-top: 4px;
    background: #F4F4F4;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    padding: 8px 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: fit-content;
}

.seasonCollapseItem_info {
    display: flex;
    align-items: center;
}

.seasonCollapseItem img {
    cursor: pointer;
}