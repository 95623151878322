.rdrDayToday .rdrDayNumber span:after {
    height: 0 !important;
}

.rdrMonth  {
    width: 40em !important;
    padding: 0 !important;
}

.rdrMonthAndYearWrapper {
    padding-top: 0 !important;
    height: 20px !important;
}