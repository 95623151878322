.uploadCard {
    width: 148px;
    height: 148px;
    background: #E4E4E4;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative;
}

.uploadCard p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: center;
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.fileIcon {
    margin-bottom: 10px;
}