.NoDataBlock {
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
    box-sizing: border-box;
    width: fit-content;
    max-width: 428px;
    height: fit-content;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
}

.NoDataBlock img {
    margin-bottom: 24px;
}