.responseCollapseItemBlock {
    margin-bottom: 16px;
}

.responseCollapseItemBlock_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    cursor: pointer;
}

.responseCollapseItemBlock_header__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.responseCollapseItemBlock.active .responseCollapseItemBlock_header img {
    transform: rotate(180deg);
}

.responseCollapseItemBlock_data {
    background: #F4F4F4;
    padding: 24px;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    margin-top: 8px;
    display: none;
}

.responseCollapseItemBlock.active .responseCollapseItemBlock_data {
    display: block;
}