.squareUploadBlock {
    display: flex;
    flex-wrap: wrap;
}

.squareUpload {
    width: 148px !important;
}

.squareUpload .ant-upload {
    height: 146px !important;
    padding: 0 !important;
    background-color: transparent !important;
}

.squareUpload .ant-upload-list {
    display: none !important;
}

.squareUploadBlock .uploadCard {
    margin-right: 16px;
    margin-bottom: 16px;
}

.calendarBlock {
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    width: fit-content;
    padding: 5px;
    margin-top: 8px;
    margin-bottom: 16px;
}