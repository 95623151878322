.signupGreate {
    text-align: center;
    max-width: 400px;
}

.signupGreate_title {
    margin-top: 24px !important;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
}

.signupGreate_description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 24px !important;
    letter-spacing: 0.25px;
}