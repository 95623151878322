.chatInfo {
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    flex-basis: 0;
    min-height: 0;
    flex-direction: column;
}

.chatInfo_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(39, 51, 51, 0.24);
}

.chatInfo_header img {
    cursor: pointer;
    margin-right: 15px;
}

.chatInfo_content {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1;
    flex-basis: 0;
    min-height: 0;
}

.chatInfo_content__messages {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.chatInfo_content__messages::-webkit-scrollbar {
    width: 0px;
    height: 0;
}

.messageBlock {
    margin-bottom: 16px;
    max-width: 321px;
    width: fit-content;
}

.readerMessage_block {
    padding: 8px 16px;
    background: #FFFFFF;
    border-radius: 16px 16px 16px 0px;
    position: relative;
    margin-left: 9px;
    margin-bottom: 4px;
}

.readerMessage_block__icon {
    position: absolute;
    left: -9px;
    bottom: 0;
}

.ownerMessage {
    margin-left: auto;
}

.ownerMessage_block {
    padding: 8px 16px;
    background: #006847;
    border-radius: 16px 16px 0px 16px;
    position: relative;
    margin-right: 9px;
    margin-bottom: 4px;
}

.ownerMessage_block__icon {
    position: absolute;
    right: -9px;
    bottom: 0;
}

.messageBlock_docs {
    margin-top: 4px;
    display: flex;
    align-items: center;
}

.messageBlock_docs img {
    margin-right: 10px;
}

.messageBlock_docs__title {
    width: 100%;
}